import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from '../components/header'
import Footer from '../components/footer'

import '../assets/scss/main.scss'

class Layout extends Component {

  state = {
    switchActive: false
  }

  componentDidMount = () => {
    let queryString = this.props.location.search
    if (queryString === '?leadgen') {
      this.setState({ switchActive: true })
      document.querySelector('html').classList.toggle('switch-off')
    }
  }

  _toggleSwitch = () => {
    this.setState({ switchActive: !this.state.switchActive })
    document.querySelector('html').classList.toggle('switch-off')
    const asteroids = document.querySelectorAll('.asteroids__image img')
    for (let i = 0; i < asteroids.length; ++i) {
      if ( this.state.switchActive) {
        asteroids[i].src = asteroids[i].getAttribute('data-digital')
      } else {
        asteroids[i].src = asteroids[i].getAttribute('data-design')
      }
    }
  }

  render() {
    let { children, data } = this.props
    let { switchActive } = this.state

    children = React.Children.map(children, (child) =>
      React.cloneElement(child, {
        toggleSwitch : this._toggleSwitch.bind(this),
        switchActive : switchActive
      })
    )

    return (
      <>
        <Header toggleSwitch={this._toggleSwitch} switchActive={switchActive} data={data} />
        <main>{children}</main>
        <Footer data={data} />
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
      fragment WpSeo on WpPostTypeSEO {
        metaDesc
        metaKeywords
        metaRobotsNofollow
        opengraphAuthor
        opengraphDescription
        opengraphImage {
          localFile {
            publicURL
          }
        }
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        readingTime
        schema {
          articleType
          pageType
          raw
        }
        title
        twitterDescription
        twitterImage {
          localFile {
            publicURL
          }
        }
        twitterTitle
      }
      fragment WpSeoTax on WpTaxonomySEO {
        metaDesc
        metaKeywords
        metaRobotsNofollow
        opengraphAuthor
        opengraphDescription
        opengraphImage {
          localFile {
            publicURL
          }
        }
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        schema {
          raw
        }
        title
        twitterDescription
        twitterImage {
          localFile {
            publicURL
          }
        }
        twitterTitle
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
)
