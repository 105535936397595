import React from 'react'
import Link from '../utils/link'
import Logo from '../images/logo.svg'
import Instagram from '../images/instagram.svg'
import Facebook from '../images/facebook.svg'
import LinkedIn from '../images/linkedin.svg'

const Footer = () => (
  <footer className='footer'>
    <div className='footer__inner'>
      <div className='footer__top'>
        <picture>
          <img src={Logo} alt='Home Navigation icon' className='footer__img' />
        </picture>
        <div className='footer__nav-bar'>
          <ul>
            <li><Link to='/blog'>Blog</Link></li>
            <li><Link to='/team'>Team</Link></li>
            <li><Link to='/projects'>Projects</Link></li>
            <li><Link to='/contact'>Contact</Link></li>
            <li>
              <Link to='https://instagram.com/wearedesigndigital'>
                <img src={Instagram} alt='Instagram' />
              </Link>
            </li>
            <li>
              <Link to='https://www.facebook.com/Design-Digital-604368576701833'>
                <img src={Facebook} alt='Facebook' />
              </Link>
            </li>
            <li>
              <Link to='https://linkedin.com/company/design-digital-agency'>
                <img src={LinkedIn} alt='YouTube' />
              </Link>
            </li>
          </ul>
        </div>
        <div className='footer__nav-bar-mobile'>
          <ul >
            <li>
              <Link to='https://instagram.com/wearedesigndigital'>
                <img src={Instagram} alt='Instagram' />
              </Link>
            </li>
            <li>
              <Link to='https://www.facebook.com/Design-Digital-604368576701833'>
                <img src={Facebook} alt='Facebook' />
              </Link>
            </li>
            <li>
              <Link to='https://linkedin.com/company/design-digital-agency'>
                <img src={LinkedIn} alt='LinkedIn' />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer