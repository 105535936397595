import React, {Component} from 'react'
import Link from '../utils/link'
import Logo from '../images/logo.svg'
import Instagram from '../images/instagram.svg'
import Facebook from '../images/facebook.svg'
import LinkedIn from '../images/linkedin.svg'
import Planet from '../images/planet.svg'

class Header extends Component {

  state = {
    headerShow: true,
    menuActive: false,
    switchActive: false,
    direction: 0,
    prevDirection: 0,
    prevScroll: 0,
    scrolled: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this._checkScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._checkScroll)
  }

  _hideOffCanvas = () => {
    this.setState({ menuActive: false })
    document.querySelector('body').classList.remove('menu-open')
  }

  _toggleOffCanvas = () => {
    this.setState({ menuActive: !this.state.menuActive })
    document.querySelector('body').classList.toggle('menu-open')
  }

  _toggleHeader = (direction, curScroll) => {
    if (direction === 2 && curScroll > 120) { 
      this.setState({ headerShow: false })
    }
    else if (direction === 1) {
      this.setState({ headerShow: true })
    }
  }

  _scrollTo = (e, id) => {
    e.preventDefault()
    document.querySelector(id).scrollIntoView({ behavior: 'smooth' })
  }

  _checkScroll = () => {

    let { direction, prevDirection, prevScroll, scrolled } = this.state 

    let curScroll = window.scrollY

    // Make sure they scroll more than 5px
    if(Math.abs(prevScroll - curScroll) <= 5) return;

    if (curScroll > prevScroll) { 
      //scrolled up
      direction = 2;
    }
    else if (window.scrollY < prevScroll) { 
      //scrolled down
      direction = 1;
    }

    if (curScroll > 1) { 
      //scrolled up
      scrolled = true;
    } else {
      scrolled = false
    }

    if (direction !== prevDirection) {
      this._toggleHeader(direction, curScroll);
    }
    
    prevScroll = curScroll;

    this.setState({ 
      direction: direction,
      prevDirection: prevDirection,
      prevScroll: prevScroll,
      scrolled: scrolled,
    })

  }

  render() {
    let { menuActive, headerShow, scrolled } = this.state

    let props = {
      onClick: this._hideOffCanvas,
    }

    return (
      <>
        <header className={`header ${scrolled ? 'header--scrolled' : ''} ${headerShow ? '' : 'header--hide'} ${menuActive ? 'active' : ''}`}>
          <div className='header__inner'>
            <div className='header__left'>
              <Link to='/' title='logo' className='header__logo' {...props}>
                <img src={Logo} alt='Design Digital' />
              </Link>
              <ul className='header__nav-bar'>
                <li><Link to='/blog/'>Blog</Link></li>
                <li><Link to='/team/'>Team</Link></li>
                <li><Link to='/projects/'>Projects</Link></li>
                <li><Link to='/contact/'>Contact</Link></li>
              </ul>
            </div>
            <div className={`header__switch ${this.props.switchActive ? 'active' : ''}`} onClick={this.props.toggleSwitch}>
              <div className='header__switch-toggle' />
            </div>
            <div className='header__landing'>
              <div>
                <img src={Planet} alt='Planet' />
                <span>Member</span>
              </div>
              <button className='btn' onClick={(e) => this._scrollTo(e, '#enquire')}>Get in touch</button>
            </div>
            <button className={ this.state.menuActive ? 'hamburger active' : 'hamburger' } onClick={this._toggleOffCanvas}>
              <span className='lines'></span>
            </button>
          </div>
        </header>
        <div className={`off-canvas ${menuActive ? 'active' : ''}`}>
          <div className='off-canvas__inner'>
            <ul className="off-canvas__inner__list">
              <li {...props}><Link to='/'>Home</Link></li>
              <li {...props}><Link to='/blog/'>Blog</Link></li>
              <li {...props}><Link to='/projects'>Projects</Link></li>
              <li {...props}><Link to='/team'>Team</Link></li>
              <li {...props}><Link to='/contact'>Contact</Link></li>
            </ul>
            <ul className='off-canvas__inner__icons'>
              <li>
                <Link to='https://instagram.com/wearedesigndigital'>
                  <img src={Instagram} alt='Instagram' />
                </Link>
              </li>
              <li>
                <Link to='https://www.facebook.com/Design-Digital-604368576701833'>
                  <img src={Facebook} alt='Facebook' />
                </Link>
              </li>
              <li>
                <Link to='https://linkedin.com/company/design-digital-agency'>
                  <img src={LinkedIn} alt='LinkedIn' />
                </Link>
              </li>
            </ul>
            <p><a href='mailto:hello@design-digital.com.au'>hello@design-digital.com.au</a></p>
            <p><a href='tel:1300212411'>1300 212 411</a></p>
          </div>
        </div>
      </>
    )
  }
}

export default Header
